import { Component, OnInit, EventEmitter, Input, ViewChildren  , Output, Renderer2, ViewChild, ElementRef ,OnDestroy, Inject } from '@angular/core';
import { ThemeService } from '../../services/theme.service';
import { LayoutService } from '../../services/layout.service';
import { TranslateService } from '@ngx-translate/core';
import { EgretNotifications2Component } from '../egret-notifications2/egret-notifications2.component';
import { Router } from '@angular/router';
import { AuthService } from 'app/shared/services/auth.service';
import {UnsubscribeOnDestroyAdapter} from 'app/shared/UnsubscribeOnDestroyAdapter'
import { InfoUserService } from 'app/shared/services/info-user.service';
import { BsModalRef, BsModalService, ModalDirective ,ModalOptions} from 'ngx-bootstrap/modal';
import { ChangeMotDePasseComponent } from 'app/views/sessions/change-mot-de-passe/change-mot-de-passe.component';
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { ProfileComponent } from '../profile/profile.component';
import { DOCUMENT } from '@angular/common';
import { RestClientService } from 'app/shared/services/rest-client.service';
import { Subscription } from 'rxjs';
import { NotificationService } from 'app/shared/services/notifications.service';
import { UrlDeconnecterService } from 'app/shared/services/url-deconnecter.service';




@Component({
  selector: 'app-header-side',
  templateUrl: './header-side.template.html',
  styleUrls: ['./header-side.component.scss'],
  providers: [{ provide: BsDropdownConfig, useValue: { isAnimated: true, autoClose: true } }]

})
export class HeaderSideComponent extends UnsubscribeOnDestroyAdapter
implements OnInit , OnDestroy {
   @ViewChildren(EgretNotifications2Component) noti;
   @ViewChild('childModal', { static: true }) childModal?: ModalDirective;
  @Input() notificPanel;
  photo: "";
  userCurrent = {};
  bsModalRef?: BsModalRef;
  nomUser:any = "";
  prenom:any = "";
  usage:any = "";
  placement = 'top left'
  isFullscenn : boolean = false
  JoursDeux : boolean = true
  joursSept : boolean = true
  busyGet: Subscription;
  dateQuelconque = '2023-08-09';
  nombrecomprissept = 5 ;



  

  public availableLangs = [{
    name: 'EN',
    code: 'en',
    flag: 'flag-icon-us'
  }, {
    name: 'ES',
    code: 'es',
    flag: 'flag-icon-es'
  }];
  currentLang = this.availableLangs[0];

  public egretThemes;
  public layoutConf: any;
  elem: any;
  items: Array<any> = [];




  constructor(
    private modalService: BsModalService,
    private layout: LayoutService, 
    public translate: TranslateService,
    private router: Router, 
    private url : UrlDeconnecterService,
    private authService: AuthService,
    private info: InfoUserService,
    private restClient: RestClientService,
    private notification: NotificationService,
    private elementRef: ElementRef,
    @Inject(DOCUMENT) private document: any

  ) {
    super()
   
  }
  ngOnInit() {
   let nombre =  this.differenceEnJours(this.dateQuelconque)
   if(nombre <= 7 && nombre > 3){
    this.joursSept = true

   }else if(nombre <= 2){
this.JoursDeux = true
this.joursSept = false

   }
    this.elem = document.documentElement;
    this.userCurrent = this.authService.currentUserValue["items"][0]
    
    this.photo = this.userCurrent["photoUser"],
    this.nomUser = this.userCurrent["nomUser"],
    this.prenom = this.userCurrent["prenomUser"],
    this.usage = this.userCurrent["roleLibelle"]
    // this.egretThemes = this.themeService.egretThemes;
    this.layoutConf = this.layout.layoutConf;
    this.translate.use(this.currentLang.code);
  }
  getItems() {
    let request = {
      user: 1,
      data: {
      },
    
    }

    this.busyGet = this.restClient.post('renouveler/getByCriteria', request)
      .subscribe(
        
        res => {
          if (res && res['items']) {
            this.items = res['items'];
            this.items.forEach((e) => {
              e.isDeleteLaSemainePro = false
            })
            this.items.forEach((e) => {

              let nombre =  this.differenceEnJours(e.date)
                if(nombre <= 7 ){
                  this.joursSept = true
                }

            })
            
          }
          else {
            this.items = [];
            
          }
        },
        err => {
        }
      );
  }
  setLang(lng) {
    this.currentLang = lng;
    this.translate.use(lng.code);
  }
  changeTheme(theme) {
    // this.themeService.changeTheme(theme);
  }
  toggleNotific() {
    this.notificPanel.toggle();
  }
  toggleSidenav() {
    if (this.layoutConf.sidebarStyle === 'compact') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full',
        sidebarCompactToggle: false
      },{transitionClass: true});
    }
    this.layout.publishLayoutChange({
      sidebarStyle: 'compact',
      sidebarCompactToggle: true
    },{transitionClass: true});
  }

  
  activerPleinEcran() {
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.mozRequestFullScreen) {
      /* Firefox */
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      /* IE/Edge */
      this.elem.msRequestFullscreen();
    }
    this.isFullscenn = true ;
  }
  quitterPleinEcran() {
    if (this.document.exitFullscreen) {
      this.document.exitFullscreen();
    } else if (this.document.mozCancelFullScreen) {
      /* Firefox */
      this.document.mozCancelFullScreen();
    } else if (this.document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      this.document.webkitExitFullscreen();
    } else if (this.document.msExitFullscreen) {
      /* IE/Edge */
      this.document.msExitFullscreen();
    }
    this.isFullscenn = false ;

  }

  changePhoto(){
    const initialState: ModalOptions = {
      backdrop: true, ignoreBackdropClick: true, class: "gray modal-lg modal-width-70",
    }
    this.bsModalRef = this.modalService.show(ProfileComponent, initialState);
    
  }

  onSearch(e) {
    //   console.log(e)
  }
  logout() {
    this.subs.sink = this.authService.logout().subscribe((res) => {
      if (!res.success) {
        this.router.navigate(["sessions/login"]);
      }
    });
  

  } 
  verouillerApp(){
    this.url.url = this.router.url;
    const nouvelElement = { key: 'url', 'value' :this.router.url  };
    localStorage.setItem('monElement', JSON.stringify(nouvelElement));
    this.router.navigate(["sessions/lockscreen"]);
  }

  openModalModifMotDePasse() {
      console.log("open modal ");
      
    }

    openModalNouveauConnecter() {
    const initialState: ModalOptions = {
      backdrop: true, ignoreBackdropClick: true, class: "gray modal-lg modal-width-35",
    }
    this.bsModalRef = this.modalService.show(ChangeMotDePasseComponent, initialState);
    this.bsModalRef.content.closeBtnName = 'Close';
    
  }
  
  differenceEnJours(date) {
    // Convertir la date donnée en objets Date
    const dateDonnee = new Date(date);
    const dateDuJour = new Date();
  
    // Réinitialiser l'heure, les minutes, les secondes et les millisecondes pour les deux dates
    dateDonnee.setHours(0, 0, 0, 0);
    dateDuJour.setHours(0, 0, 0, 0);
  
    // Calculer la différence en jours
    const differenceEnTemps = dateDonnee.getTime() - dateDuJour.getTime();
    const differenceEnJours = differenceEnTemps / (1000 * 3600 * 24);
  
    // Utiliser Math.abs() pour obtenir une valeur absolue (positive) de la différence en jours
    console.log("differenceEnJours" , differenceEnJours);
    
    return differenceEnJours;
    
  }
}
