<div class="sidebar-panel bordure">
  <div id="scroll-area" [perfectScrollbar] class="navigation-hold" fxLayout="column">
    <div class="sidebar-hold">
      <!-- App Logo -->
      <div class="branding">
        <img src="assets/images/illustrations/e-gcar.png" alt="" class="app-logo" (click)="logout()"/>
        <!-- <span class="app-logo-text">EGRET</span> -->

        <!-- <span style="margin: auto" *ngIf="layoutConf.sidebarStyle !== 'compact'"></span>
        <div
          class="sidebar-compact-switch"
          [ngClass]="{ active: layoutConf.sidebarCompactToggle }"
          (click)="toggleCollapse()"
          *ngIf="layoutConf.sidebarStyle !== 'compact'"
        >
          <span></span>
        </div> -->
      </div>
      <!-- Sidebar user -->
      <div class="app-user-photo">
        <div class="app-user">
          <!--<img src="assets/images/face-7.jpg" class="mat-elevation-z1" alt="" /> -->
          </div>
        <!-- <span class="app-user-name mb-8">
          <mat-icon class="icon-xs text-muted">lock</mat-icon>
          {{ jwtAuth?.user?.displayName }}
        </span> -->
        <!-- Small buttons -->
        <!-- <div class="app-user-controls">
          <button class="text-muted" mat-icon-button mat-xs-button [matMenuTriggerFor]="appUserMenu">
            <mat-icon>settings</mat-icon>
          </button>
          <button class="text-muted" mat-icon-button mat-xs-button matTooltip="Inbox" routerLink="/inbox">
            <mat-icon>email</mat-icon>
          </button>
          <button class="text-muted" mat-icon-button mat-xs-button matTooltip="Sign Out" routerLink="/sessions/signin">
            <mat-icon>exit_to_app</mat-icon>
          </button>
          <mat-menu #appUserMenu="matMenu">
            <button mat-menu-item routerLink="/profile/overview">
              <mat-icon>account_box</mat-icon>
              <span>Profile</span>
            </button>
            <button mat-menu-item routerLink="/profile/settings">
              <mat-icon>settings</mat-icon>
              <span>Account Settings</span>
            </button>
            <button mat-menu-item routerLink="/calendar">
              <mat-icon>date_range</mat-icon>
              <span>Calendar</span>
            </button>
            <button mat-menu-item (click)="jwtAuth.signout()">
              <mat-icon>exit_to_app</mat-icon>
              <span>Sign out</span>
            </button>
          </mat-menu>
        </div> -->
      </div>
      <!-- Navigation -->
     <div class="rien">
      <app-sidenav *ngIf="layoutConf.sidebarStyle !== 'compact'"
      [items]="menuOff"
      [hasIconMenu]="hasIconTypeMenuItem"
      [iconMenuTitle]="iconTypeMenuTitle"
    ></app-sidenav>
     </div>
      <div class="unpeu" *ngIf="layoutConf.sidebarStyle == 'compact'">
        <app-sidenav 
        [items]="menuOff"
        [hasIconMenu]="hasIconTypeMenuItem"
        [iconMenuTitle]="iconTypeMenuTitle"
      ></app-sidenav>
      </div>

      <!-- <div class="rien">
        <app-sidenav *ngIf="layoutConf.sidebarStyle !== 'compact'"
        [items]="menuItems"
        [hasIconMenu]="hasIconTypeMenuItem"
        [iconMenuTitle]="iconTypeMenuTitle"
      ></app-sidenav>
       </div>
        <div class="unpeu" *ngIf="layoutConf.sidebarStyle == 'compact'">
          <app-sidenav 
          [items]="menuItems"
          [hasIconMenu]="hasIconTypeMenuItem"
          [iconMenuTitle]="iconTypeMenuTitle"
        ></app-sidenav>
        </div> -->
    </div>
    <div class="end autre">
     <div class="start">
      <span  *ngIf="layoutConf.sidebarStyle == 'compact'">
        <div class="date d-flex justify-content-center align-items-center mb-1">
          {{ getCurrentDayInFrench() }}
          </div>
         <div class="date d-flex justify-content-center align-items-center mb-1">
           {{ currentTime | date:'dd/MM' }} 
          </div>
         <div class="heure d-flex justify-content-center align-items-center mb-2">
           {{ currentTime | date:'HH:mm' }} 
          </div>
           <div class=" d-flex justify-content-center align-items-center">
              <span class="material-icons dec" (click)="logout()">
                exit_to_app
              </span>
           </div>
      </span>
      <span *ngIf="layoutConf.sidebarStyle !== 'compact'">
        <div class="date d-flex justify-content-center align-items-center mb-1">
          {{ getCurrentDayInFrench() }}
          </div>
         <div class="date d-flex justify-content-center align-items-center mb-1">
           {{ currentTime | date:'dd/MM/yyyy' }} 
          </div>
         <div class="heure d-flex justify-content-center align-items-center mb-2">
           {{ currentTime | date:'HH:mm:ss' }} 
          </div>
           <div class=" d-flex justify-content-center align-items-center">
             <button class="deconnexion" (click)="logout()">
               <span> Deconnexion </span>
             </button>
           </div>
      </span>
     </div>
     </div>
  </div>

</div>
