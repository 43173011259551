<div class="sidenav-hold">
  <div class="icon-menu mb-16" *ngIf="hasIconTypeMenuItem">
    <!-- Icon menu separator -->
    <div class="mb-16 nav-item-sep">
      <mat-divider [ngStyle]="{margin: '0 -24px'}"></mat-divider>
      <span class="text-muted icon-menu-title">{{iconTypeMenuTitle}}</span>
    </div>
    <!-- Icon menu items -->
    <div class="icon-menu-item" *ngFor="let item of menuItems">
      <button
        *ngIf="!item.disabled && item.type === 'icon'"
        mat-raised-button
        [matTooltip]="item.tooltip"
        routerLink="/{{item.state}}"
        routerLinkActive="mat-bg-primary"
      >
        <mat-icon *ngIf="item.icon" class="sidenav-mat-icon">{{item.icon}}</mat-icon>
        <mat-icon *ngIf="item.svgIcon" [svgIcon]="item.svgIcon" class="svgIcon"></mat-icon>
      </button>
    </div>
  </div>

  <ul appDropdown class="sidenav">
    <li *ngFor="let item of menuItems" appDropdownLink  routerLinkActive="open" (click)="event($event, menuItems , item)">
      <!-- separator -->
      <div class="nav-item-sep" *ngIf="item.type === 'separator'">
        <mat-divider></mat-divider>
        <span class="text-muted">{{item.name | translate}}</span>
      </div>

      <!-- Item -->
      <div *ngIf="!item.disabled && item.type !== 'separator' && item.type !== 'icon'" class="lvl1">
        <a routerLink="/{{item.state}}" appDropdownToggle  matRipple *ngIf="item.type === 'link'">
          <mat-icon *ngIf="item.icon" class="sidenav-mat-icon">{{item.icon}}</mat-icon>
          <mat-icon *ngIf="item.svgIcon" [svgIcon]="item.svgIcon" class="svgIcon"></mat-icon>
          <span class="item-name lvl1">{{item.name | translate}}</span>
          <span fxFlex></span>
          <span
            class="menuitem-badge mat-bg-{{ badge.color }}"
            [ngStyle]="{background: badge.color}"
            *ngFor="let badge of item.badges"
            >{{ badge.value }}</span
          >
        </a>
        <a [href]="item.state" appDropdownToggle matRipple *ngIf="item.type === 'extLink'" target="_blank">
          <mat-icon *ngIf="item.icon" class="sidenav-mat-icon">{{item.icon}}</mat-icon>
          <span class="item-name lvl1">{{item.name | translate}}</span>
          <span fxFlex></span>
          <span
            class="menuitem-badge mat-bg-{{ badge.color }}"
            [ngStyle]="{background: badge.color}"
            *ngFor="let badge of item.badges"
            >{{ badge.value }}</span
          >
        </a>

        <!-- DropDown -->
        <a *ngIf="item.type === 'dropDown'" (click)="change(item ,menuItems)" appDropdownToggle matRipple  routerLinkActive="open" >
          <mat-icon *ngIf="item.icon" class="sidenav-mat-icon">{{item.icon}}</mat-icon>
          <mat-icon *ngIf="item.svgIcon" [svgIcon]="item.svgIcon" class="svgIcon"></mat-icon>
          <span class="item-name lvl1">{{item.name | translate}}</span>
          <span fxFlex></span>
          <span
            class="menuitem-badge mat-bg-{{ badge.color }}"
            [ngStyle]="{background: badge.color}"
            *ngFor="let badge of item.badges"
            >{{ badge.value }}</span
          >
          <span *ngIf="layoutConf.sidebarStyle !== 'compact'">
            <mat-icon class="menu-caret-anim plus" *ngIf=" !item.open">add</mat-icon>
            <mat-icon class="menu-caret-anim moins" *ngIf="item.open">remove</mat-icon>
          </span>
        </a>
        
        <!-- LEVEL 2 -->
        <ul class="submenu lvl2" appDropdown *ngIf="item.type === 'dropDown'">
          <li *ngFor="let itemLvL2 of item.sub" appDropdownLink routerLinkActive="open">
            <a
              routerLink="{{item.state ? '/'+item.state : ''}}/{{itemLvL2.state}}"
              appDropdownToggle
              *ngIf="itemLvL2.type !== 'dropDown'"
              matRipple
              >             
            <!-- <span fxFlex></span> -->
              <span class="lvl2 onli">
                  {{itemLvL2.name | translate}}
              </span> 
            </a>

            <a *ngIf="itemLvL2.type === 'dropDown'" appDropdownToggle matRipple>
              <span class="item-name lvl2">{{itemLvL2.name | translate}}</span>
              <span fxFlex></span>
              <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
            </a>

            <!-- LEVEL 3 -->
            <ul class="submenu lvl3" appDropdown *ngIf="itemLvL2.type === 'dropDown'">
              <li *ngFor="let itemLvL3 of itemLvL2.sub" appDropdownLink routerLinkActive="open">
                <a
                  routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state}}"
                  appDropdownToggle
                  matRipple
                >
                  <span class="item-name lvl3 onli">{{itemLvL3.name | translate}}</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </li>
  </ul>
 

    
   

</div>
