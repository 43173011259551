import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DeverouilleService {
  res : any

  constructor() { }
}
