import { Component, ViewChild, ElementRef, OnInit, Input, Output, EventEmitter, TemplateRef } from '@angular/core';
import { InfoUserService } from 'app/shared/services/info-user.service';
import { NotificationService } from 'app/shared/services/notifications.service';
import { RestClientService } from 'app/shared/services/rest-client.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.scss']
})
export class CameraComponent implements OnInit {

  modalRef: BsModalRef;

  @ViewChild('video', { static: true }) videoElement: ElementRef;
  @Output() valueEmitted = new EventEmitter<string>();
  stream: MediaStream;
  currentItemImage: {
    fileBase64: any;
    fileName: any;
    extension: any;
     typeDocument: string;
   };
  capture: any;

  video: any;
  constructor(public bsModalRef : BsModalRef, private modalService: BsModalService ,private info : InfoUserService, private notification: NotificationService,    private restClient: RestClientService,) { }


  ngOnInit() {
    this.startCamera();
  }

  async startCamera() {
    try {
      this.stream = await navigator.mediaDevices.getUserMedia({ video: true });
      this.video = this.videoElement.nativeElement;
      this.video.srcObject =  this.stream;
    } catch (err) {
      console.error("Error accessing the camera: ", err);
    }
  }

  takeSnapshot() {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = this.video.videoWidth;
    canvas.height = this.video.videoHeight;
    context.drawImage(this.video, 0, 0, canvas.width, canvas.height);
    const image = canvas.toDataURL('image/png'); 
    this.capture = image;
    this.valueEmitted.emit( this.capture);    
  }
  ngOnDestroy() {
    if (this.stream) {
      this.stream.getTracks().forEach(track => track.stop());
    }
  }
  stopCamera() {
    if (this.stream) {
      const tracks = this.stream.getTracks();
      tracks.forEach(track => track.stop());
    }else {
      console.log("impossible");
      
    }
  }
  voirImage(data: any, template:TemplateRef<any>) {
    data = this.capture;  
    // this.imageToSave = {} ;
    let config = { id :2 , backdrop: true, ignoreBackdropClick: true, class: "gray modal-lg modal-width-90 ", };
     if (data) {
      this.capture = data
     }
      this.modalRef = this.modalService.show(template,config);
  }

}
