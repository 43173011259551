import { Component, OnInit, TemplateRef , ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import * as _ from "lodash";

import { NotificationService } from 'app/shared/services/notifications.service';
import { RestClientService } from 'app/shared/services/rest-client.service';




import Swal from "sweetalert2";
import { InfoUserService } from 'app/shared/services/info-user.service';
import { CameraComponent } from './camera/camera.component';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit  {
  @ViewChild(CameraComponent, { static: false }) childComponent: CameraComponent;

  items : Array<any> = [];
  itemToSearch: any = {};
  currentPage: number = 1;
   listItems: Array<any> = [];
  itemToSave: any = {}; 
  bsValue: Date;
  maxDate = new Date(
    new Date().getFullYear() - 18,
    new Date().getMonth() - 1,
    new Date().getDay()
 );
 capture: any ; 

  user: any = {};
  itemsPerPage: number = 5;
  totalItems: number;
  emailVar: any = {};
  busyGet: Subscription;
  busySave: Subscription;
  loading: boolean = false;
  endPoint: string = '/user/';
  currentUser: any = {} 
  imageName: string;
  isNonValidEmail: boolean = false;
  imageDisplay: any;
   currentItemImage: {
    fileBase64: any;
    fileName: any;
    extension: any;
    typeDocument: string;
    
  };
  modalRef: BsModalRef;
   modalRefPrendrePhoto?: BsModalRef;
   modalRefVoirPhoto?: BsModalRef;
   image; any = "";

   userCurrent = {};
   User:any = {};
   loader : boolean = true ;
   isExiste : boolean = false
   isImage : boolean = false

   stream: MediaStream;

  rolesItems : Array<any> = [];

 constructor(public bsModalRef : BsModalRef, private modalService: BsModalService ,private info : InfoUserService, private notification: NotificationService,    private restClient: RestClientService,) { }

  
  
   confirmSaveItem(item) {
     item = this.items[0]
     let objToSave = Object.assign({}, item);
      
    
    
     
    if (this.currentItemImage && Object.keys(this.currentItemImage).length) {
      console.log("OUI OUI OUI OUI OUI");
      objToSave.document = this.currentItemImage;
     
    } else {
      console.log("NON NON NON NON NON NON NON");
     
      delete objToSave.document;
    };
      console.log(objToSave);
      
      Swal.fire({
         title: "Photo profile",
        text: objToSave?.id ? "Vous êtes sur le point de modifier votre photo de profile. Voulez-vous poursuivre cette action ?" : "Vous êtes sur le point d'enregistrer votre phpoto de profile. Voulez-vous poursuivre cette action ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0665aa",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui",
        cancelButtonText: 'Non',
      }).then((result) => {
        if (result.value) {          
          this.saveItem(objToSave);
        }
      })
  };
  voirImage(data: any, template:TemplateRef<any>) {
    data = this.imageDisplay;  
    // this.imageToSave = {} ;
    let config = { id :2 , backdrop: true, ignoreBackdropClick: true, class: "gray modal-lg modal-width-90 ", };
     if (data) {
      this.image = data
     }
      this.modalRefVoirPhoto = this.modalService.show(template,config);
  }
   saveItem(item) {
    this.loading = true;
     let itemAEnregistrer = Object.assign({}, item); 

    var request = {
     "user":"1",
      "datas": [
        itemAEnregistrer
      ]
    }
    
    this.busySave = this.restClient.post(this.endPoint+''+ (itemAEnregistrer.id ? 'update' : 'create'), request)
    .subscribe(
      res => {
          console.log(itemAEnregistrer);
          console.log("resul", res);
          this.loading = false;

          if (!res['hasError']) {
            if (res['items'] && res['items'].length > 0) {

              this.notification.showNotification("snackbar-success",
                this.notification.formatMsgServeur(res['status']['message']),
                "bottom",
                "center");

              this.getItems();

              this.bsModalRef.hide();
            }
          } else { 
            if (res['status'] && res['status']['message']) {
              this.notification.showNotification("snackbar-danger",
                this.notification.formatMsgServeur(res['status']['message']),
                "bottom",
                "center");
            }
          }
        },
        err => {
          this.notification.showNotification("snackbar-danger", this.notification.getMessageEndPointNotAvailble(),
            "bottom",
            "center");
          this.loading = false;
        }
      );
  }
  getItems() { 
    let request = {
      user: this.user.id,
      data: {
        id : this.userCurrent["id"]
      }
    }

    this.busyGet = this.restClient.post(this.endPoint+'/getByCriteria', request)
      .subscribe(
        res => {
          if (res && res['items']) {
            this.items = res['items'];
            if(this.items[0]["photoUser"]){
              this.imageDisplay = this.items[0]["photoUser"]
              this.isExiste = true
              this.isImage = true
             
              console.log("vrai");
            } else {
              this.imageDisplay ='assets/images/USer/user-ano.jpeg'
              this.isExiste = false
              this.isImage = false

            }

          }
          else {
            this.items = [];
            this.totalItems = 0;
          }
        },
        err => {
        }
      );
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.loader = false ;
    }, 1500)
    this.userCurrent = this.info.info["items"][0];
    this.getItems()
    this.getRoles();
  
    
  }
    openModal(data, template) {

        let config = { backdrop: true, ignoreBackdropClick: true, class: "gray modal-lg modal-width-65", };
      this.itemToSave = {};
      this.imageDisplay ='assets/images/USer/user-ano.jpeg'
      this.itemToSave.agent = false; 
      if (data) {
        this.itemToSave = Object.assign({}, data); 
        console.log(data);
        
       if(this.itemToSave?.idRole) {
        this.itemToSave.roleSelected = _.find(this.rolesItems, (o) => { return o.id ==  this.itemToSave?.idRole; });
       }
          if (data.dateNaissance) {
          let dateAcqui = data.dateNaissance?.split("/");
           this.itemToSave.dateNaissance = new Date(
          dateAcqui[2],
          dateAcqui[1] - 1,
          dateAcqui[0]
          );
          }
        if (data.photo) {
          console.log(data.photo);
          this.imageDisplay = data.photo
        } else {
          this.imageDisplay ='assets/images/USer/user-ano.jpeg'
        }

          console.log(this.userCurrent);
          
        
        }
        this.modalRef = this.modalService.show(template, config);
    }
    uploadFile(event: any) {
      const reader = new FileReader();
      if (event.target.files && event.target.files.length) {
        const file = event.target.files[0];
        const fileName = file.name.split(".")[0];
        const Tabextension = file.name.split(".");
        const extension = Tabextension[Tabextension.length - 1];
  
        // console.log("fileName", fileName);
        // console.log("extension", extension);
        this.imageName = fileName + "." + extension;
        reader.onload = (readerEvent) => {
          const data = (readerEvent.target as any).result;
          this.imageDisplay = data;
          const fileBase64 = data.split(",")[1];
          this.currentItemImage = {
            fileBase64: fileBase64,
            fileName: fileName,
            extension: extension,
            typeDocument: "custom type",
          };
        };
      }
      reader.readAsDataURL(event.target.files[0]);
      this.isImage = true
    }

  
    prendrePhoto (data: any, template:TemplateRef<any>){
      data = this.imageDisplay;  
      let config = { id :3 , backdrop: true, ignoreBackdropClick: true, class: "gray modal-lg modal-width-90  ", };
         if(data){
               this.image = data
              }
        this.modalRefPrendrePhoto = this.modalService.show(template,config);
    }
  
    
  getRoles() {
    let request = {
      user: this.user.id,
      data: {
        libelle: this.itemToSearch.libelle ? this.itemToSearch.libelle : null
      },
    }

    this.busyGet = this.restClient.post('/role/getByCriteria', request)
      .subscribe(
        res => {
          if (res && res['items']) {
            this.rolesItems = res['items'];
          }
          else {
            this.rolesItems = [];
          }
        },
        err => {
        }
      );
  }
  confirmDelete(){
    this.imageDisplay ='assets/images/USer/user-ano.jpeg'
              this.isExiste = false
              this.isImage = false
  }
  getImage(){
    console.log("imagr capturer est " , this.capture);
    
  }
  getValueFromChild(value: string) {
    this.capture = value;
    this.imageDisplay = value ;
    console.log(this.capture);
    const fileName = "image";
    const extension = "png";
    const fileBase64 = value.split(",")[1];
    this.imageName = fileName + "." + extension
    if(this.capture){
      this.isImage = true
      this.isExiste = true
      this.currentItemImage = {
        fileBase64: fileBase64,
        fileName: fileName,
        extension: extension,
        typeDocument: "custom type",
      };
    
    }else {

    }
     
   
  }
 

  executeFunctionInChild() {
    if (this.childComponent) {
      this.childComponent.stopCamera();
    }
  }

}
