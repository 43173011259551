

    <div class="modal-header">
        <h4 class="modal-title pull-left">
            {{ isExiste ? "Modifier" : "Enregistrer" }} photo de profil
         </h4>

        <button mat-icon-button class="pull-right" (click)="bsModalRef.hide()" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </div> 
    <div>
      <form>
            <div class="modal-body"  style="background-color: white;">
                        <fieldset>
                            <legend> </legend>  
                            <div class="col-12">
                                <div class="row"> 
                                    <div class=" col-lg-12 col-md-12 " >
                                        <div class=" image d-flex justify-content-center align-items-center">
                                            <div class=" fade-in-image image" *ngIf="!loader">
                                                <img src="{{imageDisplay}}" style="
                                                width: 500px;
                                                height: 500px;
                                                display: block; 
                                                border-radius: 50%; 
                                                " />
                                            </div>
                                            <!--  -->
                                            <div class="" *ngIf="!loader" title="cliquer pour charger la photo">
                                                <div class="btn-group remplace" dropdown>
                                                  <button id="button-animated" dropdownToggle type="button"
                                                  class="  text" aria-controls="dropdown-animated">
                                                 <div class="d-flex flex-column justify-content-center gap-2">
                                                  <span class="material-symbols-outlined fontS">
                                                    photo_camera
                                                    </span>
                                                   
                                                     <span>
                                                      {{ isExiste ? "CHANGER" : "CHOISIR" }} LA PHOTO DE PROFIL 
                                                     </span>
                                                 </div>
                                                    </button>
                                                    <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu messi" role="menu"
                                                      aria-labelledby="button-animated">
                                                  
                                                      <li role="menuitem" *ngIf="isImage">
                                                        <span title="Description"  (click)="voirImage(item, templateImage)">
                                                          <div class="d-flex justify-content-start align-items-center eusssss">
                                                            <span class="material-symbols-outlined ms-2">
                                                              loupe
                                                              </span>
                                                            <span class="ms-2 me-2">
                                                                Voir la photo de profil
                                                            </span>
                                                           </div>
                                                        </span>
                                                      </li>
                                                      <li role="menuitem" *ngIf="isExiste">
                                                        <span title="Supprimer" (click)="confirmDelete()">
                                                          <div class="d-flex justify-content-start align-items-center eusssss">
                                                            <span class="material-symbols-outlined ms-2">
                                                              no_accounts
                                                              </span>
                                                            <span class="ms-2 me-2">
                                                              Supprimer la photo de profil
                                                            </span>
                                                            
                                    
                                                          </div>
                                                        </span>
                                                      </li>
                                                      <li role="menuitem">
                                                        <span title="Importer">
                                                          <label for="img" class="w-100">
                                                            <div class="d-flex justify-content-start align-items-center eusssss">
                                                                <span class="material-symbols-outlined ms-2">
                                                                  flip_camera_ios
                                                                  </span>
                                                                 <span class="ms-2 me-2">
                                                                    Importer la photo de profil
                                                                </span>
                                                                
                                                              </div>
                                                            </label>
                                                        </span>
                                                      </li>
                                                      <li role="menuitem">
                                                        <span title="Capture" (click)="prendrePhoto(item, Photo)">
                                                            <div class="d-flex justify-content-start align-items-center eusssss">
                                                              
                                                              <span class="material-symbols-outlined ms-2">
                                                                add_a_photo
                                                                </span>
                                                               <span class="ms-2 me-2">
                                                                    prendre la photo de profil
                                                                </span>
                                                                
                                                           
                                                            </div>
                                                        </span>
                                                      </li>
                                                    </ul>
                                                  </div> 
                                              
                                            </div>
                                            <div class="" *ngIf="loader" style=" overflow: hidden !important;
                                            width: 500px;
                                            height: 500px;

                                            display: block; border-radius: 50%;
                                            " >
                                                <div class="spinnner"> 
                                                    <div class="position-relative">
                                                      <div class="spinner-border text-success custom-spiner" role="status">
                                                      </div>
                                                    </div>
                                                  </div>
                                            </div>
    
                                            </div>
                                            <input hidden id="img" type="file" class="custom-input form-control"
                                                (change)="uploadFile($event)" [(ngModel)]="itemToSave.image" accept=".png, .jpg, .jpeg, .bmp" name="image"
                                                 />
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        
                      </div>
                    </form>
    </div>
    <div class="modal-footer" style="background-color: white;">
        <button type="button" class="btn btn-cancel btn-sm" (click)="bsModalRef.hide()">Fermer</button>
        <button type="submit" (click)="confirmSaveItem(itemToSave)" class="btn btn-save btn-sm" [disabled]="!itemToSave.libelle"
          [class.auth-spinner]="loading" [disabled]="loading">
          {{ isExiste ? "Modifier" : "Enregistrer" }}
        </button>
      </div>
  


      <ng-template #templateImage>
        <div class="modal-header perso">
            <h4 class="modal-title pull-left">
                <!-- {{ isExiste ? "Modifier" : "Enregistrer" }} photo de profil -->
             </h4>
    
            <button mat-icon-button class="pull-right" (click)="modalRefVoirPhoto?.hide()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <div class="modal-body ub"  style="background-color: white;">
              <div class="row">
               <div class="col-12">
                    <div class="row">
                        <div class="d-flex justify-content-center align-items-center">
                            <img src="{{image||imageToSave}}" style="width: 80%;
                height: 810px;
                display: block;
                text-align: center;
                justify-content: center;" >

                        </div>
                    </div>
               </div>
              </div> 
        </div>
      </ng-template> 
      <ng-template #Photo>
        <div class="modal-header perso">
            <h4 class="modal-title pull-left">
                <!-- {{ isExiste ? "Modifier" : "Enregistrer" }} photo de profil -->
             </h4>
    
            <button mat-icon-button class="pull-right" (click)="modalRefPrendrePhoto.hide();executeFunctionInChild();getImage()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <div class="modal-body ub"  style="background-color: white;">
              <div class="row">
               <div class="col-12">
                    <div class="row">
                        <div class="d-flex justify-content-center align-items-center comme">
                          <span style="width: 40%;
                          height: 810px;
                          display: block;
                          text-align: center;
                          justify-content: center;" >
                            <app-camera (valueEmitted)="getValueFromChild($event)"></app-camera>
                          </span>
                        </div >

                    </div>
               </div>
              </div>
        </div>
      </ng-template>

