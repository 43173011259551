
<div class="modal-header custom-modal-header">
    <h4 class="modal-title pull-left"> 
        Previsualisation
    </h4>
    <button mat-icon-button class="pull-right" (click)="hideModale()" aria-label="Close dialog">
      <mat-icon>close</mat-icon>
  </button>
  </div>
  <div class="modal-body" [ngBusy]="{
    busy: busyGet2,
    message: 'Veuillez patienter ...',
    backdrop: true
  }" >
     <div class="row">
  
      <div class="form-group col-md-12" >

          <div id="fancybox-content" style="border-width: 10px; width: auto; height: 100%;" *ngIf="itemToSave.fileUrlFinale">
              <div style="width:100%;height:100%;overflow: hidden;position:relative;">
    
                <object [data]='itemToSave.fileUrlFinale'  type="application/pdf" style="height:600px;width:100%" id="objPdf" internalinstanceid=''>
                  <embed [src]='itemToSave.fileUrlFinale'  type="application/pdf" style="height:600px;width:100%" id="embedPdf" /> 
                </object>
    
              </div>
            </div>
      </div>
   
     </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default btn-cancel" (click)="hideModale()">Fermer</button>
  </div>