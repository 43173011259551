import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UrlDeconnecterService } from '../services/url-deconnecter.service';
import { DeverouilleService } from '../services/deverouille.service';

@Injectable({
  providedIn: 'root'
})
export class DeverouillageGuard implements CanActivate {

  constructor(private urlService: UrlDeconnecterService , private dev: DeverouilleService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const data = this.urlService.getItem('currentUser');
      const data2 = this.urlService.getItem('monElement');
      if(data2){
        if(data["items"][0]["id"] == this.dev.res ) {
          return true;
        } else {
          return false
        }
      } else {
        return true
      }
  }
  
}

// 