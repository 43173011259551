<div class="modal-header">
    <h4 class="modal-title pull-left"> {{title}} </h4>

    <button mat-icon-button class="pull-right" (click)="bsModalRef.hide()" aria-label=" Close dialog">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div class="modal-body" style="background-color: white;">

<div class="col-12">

    <form class="m-1">
        <div class="row">
            <div class="col-10 m-auto p-2">
                <label>Login :
                    <span class="required_champs">*</span>
                </label>
                <input class="form-control custom-input ecrire col-12 col-md-11 col-sm-11" type="text"
                    disabled placeholder="Login" autocomplete="off" [(ngModel)]="itemToSave.login"
                    name="login" required type="text">
            </div>
            <div class="col-10 m-auto p-2">
                <label>Ancien mot de passe :
                    <span class="required_champs">*</span>
                </label>
                <input class="form-control custom-input ecrire col-12 col-md-11 col-sm-11" type="password"
                    placeholder="Ancien mot de passe" autocomplete="off" [(ngModel)]="itemToSave.password"
                    name="password" required type="password">
            </div>
            <div class="col-10 m-auto p-2">
                <label>Nouveau mot de passe :
                    <span class="required_champs">*</span>
                </label> 
                <input class="form-control custom-input ecrire col-12 col-md-11 col-sm-11" type="password"
                    placeholder="Nouveau mot de passe" autocomplete="off" [(ngModel)]="itemToSave.newPassword"
                    name="newPassword" required type="password"> 
            </div>
            <div class="col-10 m-auto p-2">
                <label>Confirmer mot de passe :
                    <span class="required_champs">*</span>
                </label>
                <input class="form-control custom-input ecrire col-12 col-md-11 col-sm-11" type="password"
                    placeholder="Confirmer mot de passe" autocomplete="off" [(ngModel)]="itemToSave.ConfirmnewPassword"
                    name="ConfirmnewPassword" required type="password">
            </div>

        </div>
    </form> 
</div>

</div>
<div class="modal-footer" style="background-color: white;">
        <button type="button" class="btn btn-sm btn-cancel pull-right" (click)="bsModalRef.hide()">
            fermer
        </button>
        <button type="submit" (click)="confirmSaveItem(itemToSave)" class="btn btn-save btn-sm" [disabled]="!itemToSave.libelle"
        [class.auth-spinner]="loading" [disabled]="loading">
        {{ itemToSave?.id ? "Modifier" : "Enregistrer" }}
      </button>

    </div>
