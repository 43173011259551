<mat-toolbar class="topbar mat-bg-card stylecard">
  <!-- Sidenav toggle button -->
  <button mat-icon-button (click)="toggleSidenav()">
    <mat-icon class="icon2">menu</mat-icon>
  </button>



  <div class=" d-flex justify-content-center align-items-center gauche  ">
    <button mat-icon-button  *ngIf="!isFullscenn"  (click)="activerPleinEcran()" title="Passer en plein eran">
      <mat-icon class="icon2">fullscreen</mat-icon>
    </button>
    <button mat-icon-button *ngIf="isFullscenn" (click)="quitterPleinEcran()" title="Quiter le mode plein ecran">
      <mat-icon class="icon2">fullscreen_exit</mat-icon>
    </button>
  </div>
 

  <div class=" d-flex justify-content-center align-items-center  milieu  ">

    <button mat-icon-button *ngIf="joursSept" [popover]="'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.'"
    popoverTitle="Notifications" container="body" containerClass="customClass" [delay]="500" [outsideClick]="true"  class="notificationImportant"
    [placement]="placement">
    <span class="material-symbols-outlined"[matBadge]=nombrecomprissept matBadgeColor="warn" matBadgeOverlap="true">
      notifications_active
      </span>
      
    </button>
  </div>
  

<!-- [popover]="popTemplate" -->


  <span fxFlex></span>
 
  <!-- Top left user menu -->
 
  
 <div class="passs">
   <div class="btn-group d-flex justify-content-center align-items-center" dropdown>
  <button   dropdownToggle id="button-animated" class="topbar-button-right img-button text"  aria-controls="dropdown-animated">
    <span class="identifiant" style="cursor: pointer;" >
      {{nomUser | uppercase}} {{prenom | uppercase}}  <span class="ms-2 me-2"> ({{usage | uppercase}})</span>
    </span>
  
    <img src="{{photo ||'assets/images/USer/user-ano.jpeg'}}" class="image mat-elevation-z1 ms-2" alt="">
  </button>
    <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu touch"
        role="menu" aria-labelledby="button-animated">
      <li role="menuitem" (click)="openModalNouveauConnecter()">
        <a class="dropdown-item d-flex justify-content-between align-items-center">
        <button mat-menu-item >
          <div class="d-flex justify-content-between align-items-center" >
            <span class="material-icons ms-2 icon">
              manage_accounts
            </span>
            <span class="petit ms-2">Modifier mot de passe</span>
          </div>
        </button>
      </a>
    </li>
      <li role="menuitem" (click)="changePhoto()"><a class="dropdown-item d-flex justify-content-between align-items-center">
        <button mat-menu-item >
          <div class="d-flex justify-content-between align-items-center">
            
            <span class="material-icons ms-2 icon">
              switch_account
            </span>
            <span class="petit ms-2">Profile</span>

          </div>
        </button>
 
       </a>
      </li>
      <li role="menuitem" (click)="verouillerApp()"><a class="dropdown-item d-flex justify-content-between align-items-center">
        <button mat-menu-item >
          <div class="d-flex justify-content-between">
            <span class="material-icons ms-2 icon">
              lock
            </span>
            <span class="petit ms-2">Verrouillé</span>
          </div>
        </button>

       </a>
      </li>
      <li role="menuitem" (click)="logout()"><a class="dropdown-item d-flex justify-content-between align-items-center">
        <button mat-menu-item >
          <div class="d-flex justify-content-between align-items-center">
            <span class="material-icons ms-2 icon">
              exit_to_app
            </span>
            <span class="petit ms-2">Deconnexion</span>

          </div>
        </button>

       </a>
      </li>

    </ul>
  </div>
 </div>
  
</mat-toolbar> 

<ng-template #popTemplate>Just another: {{content}}</ng-template>