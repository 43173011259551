import { DatePipe } from '@angular/common';
import { Component, OnInit, Input ,AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UnsubscribeOnDestroyAdapter } from 'app/shared/UnsubscribeOnDestroyAdapter';
import { AuthService } from 'app/shared/services/auth.service';
import { ILayoutConf, LayoutService } from "app/shared/services/layout.service";


@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.template.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent extends UnsubscribeOnDestroyAdapter implements OnInit  {
  @Input('items') public menuItems: any[] = [];
  @Input('hasIconMenu') public hasIconTypeMenuItem: boolean;
  @Input('iconMenuTitle') public iconTypeMenuTitle: string;
  currentTime: Date;
  today = new Date();
  dayOfWeek = Date;
  public layoutConf: ILayoutConf;
  

 

  constructor(
    public translate: TranslateService,
    private router: Router, private authService: AuthService,
    private datePipe: DatePipe,
    private layout: LayoutService, 

  ) {
    super()
  }
  ngOnInit() {
    this.layoutConf = this.layout.layoutConf;
     this.currentTime = new Date();
     setInterval(() => {
      this.getCurrentDayInFrench()
      this.currentTime = new Date();  
    }, 1000); 
    
  }
 
  logout() {
    this.subs.sink = this.authService.logout().subscribe((res) => {
      if (!res.success) {
        this.router.navigate(["sessions/login"]);
      }
    });
  } 

  isRouteActive(route: string): boolean {
    return this.router.isActive(route, true);
  }
  getCurrentDayInFrench(): string {
    const today = new Date();
    let formattedDay = this.datePipe.transform(today, 'EEEE', 'fr');
    if(formattedDay == 'Monday'){
      formattedDay = "Lundi"
    }else if(formattedDay == 'Tuesday'){
      formattedDay = "Mardi"
    } else if(formattedDay == 'Wednesday'){
      formattedDay = "Mercredi"
    }else if(formattedDay == 'Thursday'){
      formattedDay = "Jeudi"
    }else if(formattedDay == 'Friday'){
      formattedDay = "Vendredi"
    }else if(formattedDay == 'Saturday'){
      formattedDay = "Samedi"
    }else if(formattedDay == 'Sunday'){
      formattedDay = "Dimanche"
    }
    return formattedDay;
  }
  event(e , menuItems , item){
    menuItems.forEach((e) => {
      if(e.name == item.name ){
        e.open = e.open? true : false
      }else if(e.name == item.name && e.open){
        e.open = false
      }else{
        e.open = false
      }
    })
  }
  change(item , array ){
    item.open = !item.open
  }

  // Only for demo purpose
  addMenuItem() {
    this.menuItems.push({
      name: 'ITEM',
      type: 'dropDown',
      tooltip: 'Item',
      icon: 'done',
      state: 'material',
      sub: [
        {name: 'SUBITEM', state: 'cards'},
        {name: 'SUBITEM', state: 'buttons'}
      ]
    });
  }
  toggleCollapse() {
    if (
      this.layoutConf.sidebarCompactToggle
    ) {
        console.log(this.layoutConf.sidebarCompactToggle);
        
        this.layout.publishLayoutChange({
        sidebarCompactToggle: false
      });
    } else {
        this.layout.publishLayoutChange({
            sidebarCompactToggle: true
          });
          console.log(this.layoutConf.sidebarCompactToggle);

    }
  }
}