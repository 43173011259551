import { Component, Input, OnInit } from '@angular/core';
import { NotificationService } from 'app/shared/services/notifications.service';
import { RestClientService } from 'app/shared/services/rest-client.service';
import { BsModalRef, BsModalService , } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import * as _ from "lodash";
import * as moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthService } from 'app/shared/services/auth.service';

@Component({
  selector: 'app-visualisation-autre-pdf',
  templateUrl: './visualisation-autre-pdf.component.html',
  styleUrls: ['./visualisation-autre-pdf.component.scss']
})
export class VisualisationAutrePdfComponent implements OnInit {
  itemToSave: any;
  endPointReport: any;
  currentLink: any;
  typeFile: String = "";
  isGenererQuitus: boolean = false;
  user: any = {};
  currentPrestataire: any = {};
  busyGet2: boolean = false;
  titleReportDocumentSortie: string;

  constructor(
    public sanitizer: DomSanitizer,
    private restClient: RestClientService,
    public bsModalRefPreview: BsModalRef,
    public router: Router,
    private userService: AuthService
  ) {
    this.itemToSave = { isReady: false };
    this.getCurrentFile();
  }

  gotoDashbordPage() {
    this.hideModale();
    this.router.navigate(["/dashbord"]);
  }

  getCurrentFile() {
   

    setTimeout(() => {
      let link = this.currentLink;
      this.cleanURL(link);
    }, 1000);
  }

  cleanURL(oldURL) {

    try {
      if (!this.itemToSave.isReady && oldURL) {
        this.busyGet2 = true ;
        setTimeout(() => {
          this.busyGet2 = false
        }, 3000) 
        this.itemToSave.isReady = true;
        this.itemToSave.fileUrlFinale = this.sanitizer.bypassSecurityTrustResourceUrl(oldURL);
      }
    } catch (error) {
      
    }
   
  }

  hideModale() {
    // this.bsModalRefPreview.content.isSave = false;
    this.bsModalRefPreview.hide();
  }

  ngOnInit() { }

}
