<div class="d-flex justify-content-center align-content-center flex-column comme">
	<video #video autoplay>
    
  </video>
  <span (click)="takeSnapshot()" title="prendre la photo" class="material-icons photo">
      photo_camera_back
  </span>
  <img src="{{capture}}"  title="cliquer pour agrandir "  (click)="voirImage(item, templateImage)"  *ngIf="capture" alt="" style=" cursor: pointer;  width: 100%; height: 300px; border-radius: 2rem; border: 1px #555 ;">
  </div>
   
  <ng-template #templateImage>
    <div class="modal-header perso">
        <h4 class="modal-title pull-left">
            <!-- {{ isExiste ? "Modifier" : "Enregistrer" }} photo de profil -->
         </h4>

        <button mat-icon-button class="pull-right" (click)="modalRef?.hide()" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="modal-body ub"  style="background-color: white;">
          <div class="row">
           <div class="col-12">
                <div class="row">
                    <div class="d-flex justify-content-center align-items-center">
                        <img src="{{capture}}" style="width: 80%;
            height: 810px;
            display: block;
            text-align: center;
            justify-content: center;" >

                    </div>
                </div>
           </div>
          </div> 
    </div>
  </ng-template> 