import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface IMenuItem {
  type: string; // Possible values: link/dropDown/icon/separator/extLink
  name?: string; // Used as display text for item and title for separator type
  state?: string; // Router state
  icon?: string; // Material icon name
  svgIcon?: string; // UI Lib icon name
  tooltip?: string; // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
}
interface IChildItem {
  type?: string;
  name: string; // Display text
  state?: string; // Router state
  icon?: string; // Material icon name
  svgIcon?: string; // UI Lib icon name
  sub?: IChildItem[];
}

interface IBadge {
  color: string; // primary/accent/warn/hex color codes(#fff000)
  value: string; // Display text
}

@Injectable()
export class NavigationService {
  iconMenu: IMenuItem[] = [
    {
      name: 'Tableau de bord',
      type: 'link',
      tooltip: 'Dashboard',
      icon: 'house',
      state: '/dashboard',
    },
    {
      name: 'Etat',
      type: 'dropDown',
      tooltip: 'Material Icons',
      icon: 'museum',
      state: 'etat/',
      sub: [
        { name: 'Course', state: '/course' },
        { name: 'Tresorerie', state: '/tresorerie' },
      ],
      // sub: [
      //   {
      //     name: 'Course ',
      //     state: 'course',
      //     type: 'dropDown',
      //     sub: [
      //       { name: 'Journalier', state: 'journalier' },
      //     ],
      //   },
      //   {
      //     name: 'Commission',
      //     state: 'commission',
      //     type: 'dropDown',
      //     sub: [
      //       { name: 'Journalier', state: 'journalier' },
      //     ],
      //   },
      // ],
    },
    
    {
      name: 'Reception',
      type: 'dropDown',
      tooltip: 'Material Icons',
      icon: 'list',
      state: 'reception',
      sub: [
        { name: 'Chauffeur', state: '/chauffeur' },
        { name: 'Vehicule', state: '/vehicule' },
      ],
    },
    {
      name: 'Course',
      type: 'dropDown',
      tooltip: 'Material Icons',
      icon: 'local_taxi',
      state: 'course/',
      sub: [
        {
          name: 'Taxi',
          state: 'course-taxi',
          type: 'dropDown',
          sub: [
            { name: 'Nouvelle course', state: 'en-cours' },
            { name: 'Course terminée', state: 'terminee' },
            { name: 'Commission', state: 'commission' },
          ],
        },
        // {
        //   name: 'Yango',
        //   state: 'course-yango',
        //   type: 'dropDown',
        //   sub: [
        //     { name: 'Rechargement Yango', state: 'rechargement-yango' },
        //     { name: 'Nouvelle course', state: 'en-cours' },
        //     { name: 'Course terminée', state: 'terminee' },
        //   ],
        // },
      ],
    },
    // {
    //   name: 'Gestion commerciale',
    //   type: 'dropDown',
    //   tooltip: 'Material Icons',
    //   icon: 'payments',
    //   state: 'documentation',
    //   sub: [
    //     {
    //       name: 'Facture',
    //       type: 'dropDown',
    //       state: 'learning-management',
    //       sub: [{ name: 'Facture commande', state: 'analytics' }],
    //     },
    //   ],
    // },

    // {
    //   name: 'Location de vehicule',
    //   type: 'dropDown',
    //   tooltip: 'Material Icons',
    //   icon: 'local_shipping',
    //   state: '/location-voiture',
    //   sub: [
    //     { name: 'Client', state: '/client' },
    //     { name: 'Reservation', state: '/commande' },
    //     { name: 'Proformat', state: '/proformat' },
    //     { name: 'Facture', state: '/facture' },
    //     { name: 'Reglement', state: '/reglement' },
    //     { name: 'Commission', state: '/commission' },
    //   ],
    // },
    // {
    //   name: 'Intervention',
    //   type: 'dropDown',
    //   tooltip: 'Material Icons',
    //   icon: 'local_shipping',
    //   state: '/intervention', 
    //   sub: [
    //     { name: 'Declaration', state: '/declaration' },
    //     { name: 'Prestataire', state: '/prestataire' },
    //     { name: 'Devis Intervenant', state: '/devis-Intervenant' },
    //     { name: 'Ordre-Intervention', state: '/ordre-Intervention' },
    //     { name: 'Intervention', state: '/intervention' },
    //     { name: 'Facture', state: '/facture' },
    //     { name: 'Reglement', state: '/reglement' },
    //   ],
    // },
    {
      name: 'Document administratif',
      type: 'dropDown',
      tooltip: 'Material Icons',
      icon: 'library_books',
      state: '/document-administratif',
      sub: [
        { name: 'Renouvellement pièces', state: '/pieces-administratives' },
        // { name: 'Renouvellement', state: '/renouveler' },
      ],
    },
    // {
    //   name: 'Entretien',
    //   type: 'dropDown',
    //   tooltip: 'Material Icons',
    //   icon: 'home_repair_service',
    //   state: '/reparation',
    //   sub: [
    //     {
    //       name: 'Planning',
    //       state: '/reception-vehicule',
    //     },
    //     {
    //       name: 'Consultation entretien',
    //       state: '/saisie-diagnostic',
    //     },
    //     {
    //       name: 'Execution entretien',
    //       state: '/devis',
    //     },
    //   ],
    // },
    // {
    //   name: 'Reparation interne',
    //   type: 'dropDown',
    //   tooltip: 'Material Icons',
    //   icon: 'home_repair_service',
    //   state: '/reparation',
    //   sub: [
    //     {
    //       name: 'Reception vehicule',
    //       state: '/reception-vehicule',
    //     },
    //     {
    //       name: 'Diagnostic',
    //       state: '/saisie-diagnostic',
    //     },
    //     {
    //       name: 'Devis',
    //       state: '/devis',
    //     },
    //     {
    //       name: 'Valider devis',
    //       state: '/valider-devis',
    //     },

    //   ],
    // },
    // {
    //   name: 'Atélier',
    //   type: 'dropDown',
    //   tooltip: 'Material Icons',
    //   icon: 'build',
    //   state: '/atelier',
    //   sub: [
    //     {
    //       name: 'Ordre reparation',
    //       state: '/ordre-reparation',
    //     },
    //     {
    //       name: 'Intervenant',
    //       state: '/intervenant',
    //     },
    //     {
    //       name: 'Reparation',
    //       state: '/reparation',
    //     },
    //     {
    //       name: 'Contrôle qualité',
    //       state: '/controle-qualite',
    //     },
    //     {
    //       name: 'Bon de sortie',
    //       state: '/bon-de-sortie/bon-sortie',
    //     },
    //   ],
    // },

     {
      name: 'Gestion magasin',
      type: 'dropDown',
      tooltip: 'Material Icons',
      icon: 'webhook',
      state: '/magasin',
      sub: [
        { name: 'Inventaire', state: '/inventaire', type: 'link' },
        { name: 'Stockage', state: '/stockage', type: 'link' },
        { name: 'Sortie diverse', state: '/sortie-diverse', type: 'link' },
        { name: 'Rangement', state: '/rangement', type: 'link' },
      ],
    },

 
    {
      name: 'Tresorerie',
      type: 'dropDown',
      tooltip: 'Material Icons',
      icon: 'euro',
      state: '/tresorerie', 
      sub: [
        { name: 'Recette course', state: 'validation-course-recette' },
        { name: 'Crédit', state: '/credit' },
        { name: 'Caisse', state: '/recette' },
        { name: 'Depense', state: '/depense' },
        { name: 'Reglement commission', state: '/reglement-commission' },
      ],
    },
    {
      name: 'Banque',
      type: 'dropDown',
      tooltip: 'Material Icons',
      icon: 'account_balance',
      state: '/banque', 
      sub: [
        { name: 'Versement', state: '/versement' },
        { name: 'Rétrait', state: '/retrait' },
      ],
    },

    {
      name: 'Parametres',
      type: 'dropDown',
      tooltip: 'Dashboard',
      icon: 'settings',
      state: '/parametres',
      sub: [
        {
          name: 'P Administration',
          state: '/administrative',
        },
        {
          name: 'P Atélier',
          state: '/atelier',
        },
        {
          name: 'P Banque',
          state: '/banque',
        },
        {
          name: 'P Client',
          state: '/client',
        },
        {
          name: 'P Course',
          state: '/course',
        },
        {
          name: 'P Document administratif',
          state: '/doucument',
        },
        {
          name: 'P Magasin',
          state: '/magasin',
        },
        {
          name: 'P Reception',
          state: '/reception',
        },
       
        
        {
          name: 'P Réparation',
          state: '/reparation',
        },
       
        {
          name: 'P Trésorerie',
          state: '/tresorerie',
        },
        
       
      ],
    },
    {
      name: 'Administration',
      type: 'dropDown',
      tooltip: 'Documentation',
      icon: 'lock',
      state: 'administration',
      sub: [
        { name: 'Utilisateurs', state: 'utilisateurs', type: 'link' },
        { name: 'Role', state: 'role', type: 'link' },
        { name: 'Fonctionnalite', state: 'fonctionnalite', type: 'link' },
      ],
    },
  ];

  // Icon menu TITLE at the very top of navigation.
  // This title will appear if any icon type item is present in menu.
  iconTypeMenuTitle = 'Frequently Accessed';
  // sets iconMenu as default;
  menuItems = new BehaviorSubject<IMenuItem[]>(this.iconMenu);
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();
  constructor() {}

  // Customizer component uses this method to change menu.
  // You can remove this method and customizer component.
  // Or you can customize this method to supply different menu for
  // different user type.
  publishNavigationChange(menuType: string) {
    // switch (menuType) {
    //   case 'separator-menu':
    // this.menuItems.next(this.separatorMenu);
    // break;
    //   case 'icon-menu':
    this.menuItems.next(this.iconMenu);
    // break;
    //   default:
    // this.menuItems.next(this.plainMenu);
    // }
  }
}
