import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pipeArgent'
})
export class PipeArgentPipe implements PipeTransform {

  transform(value: any, args?: any): string {
    if(!value) return;

    let nombre=value;
    nombre += '';
    let sep = ' ';
    let reg = /(\d+)(\d{3})/;

    while (reg.test(nombre)) {
        nombre = nombre.replace(reg, '$1' + sep + '$2');
    }

    return nombre;
}
}
