import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './shared/components/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { DeverouillageGuard } from './shared/guards/deverouillage.guard';

export const rootRouterConfig: Routes = [
  {
    path: '',
    redirectTo: 'sessions',
    pathMatch: 'full',
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'sessions',
        loadChildren: () => import('./views/sessions/sessions.module').then((m) => m.SessionsModule),
      },
    ],
  },
  {
    path: '',
    canActivate: [AuthGuard, DeverouillageGuard],
    component: AdminLayoutComponent,
    children: [
      {
        path: 'dashboard',
        canActivate: [AuthGuard, DeverouillageGuard],
        loadChildren: () => import('./views/dashboard/dashboard.module').then((m) => m.DashboardModule),
       
      },
      {
        
        path: 'parametres',
        canActivate: [AuthGuard, DeverouillageGuard],
        loadChildren: () => import('./views/parametre/param.module').then((m) => m.ParamModule),
      },
      {
        
        path: 'acceuil',
        loadChildren: () => import('./views/acceuil/acceuil.module').then((m) => m.AcceuilModule),
      },
      {
        
        path: 'reception',
        canActivate: [AuthGuard, DeverouillageGuard],
        loadChildren: () => import('./views/reception/reception.module').then((m) => m.ReceptionModule),
      },
      {
        
        path: 'course',
        canActivate: [AuthGuard, DeverouillageGuard],
        loadChildren: () => import('./views/courses/course.module').then((m) => m.CourseModule),
      },
      {
        
        path: 'intervention',
        canActivate: [AuthGuard, DeverouillageGuard],
        loadChildren: () => import('./views/intervention/intervention.module').then((m) => m.InterventionModule),
      },
      {
        
        path: 'etat',
        canActivate: [AuthGuard, DeverouillageGuard],
        loadChildren: () => import('./views/etat/etat.module').then((m) => m.EtatModule),
      },
      {
        
        path: 'banque',
        canActivate: [AuthGuard, DeverouillageGuard],
        loadChildren: () => import('./views/banque/banque.module').then((m) => m.BanqueModule),
      },
      {
        
        path: 'tresorerie',
        canActivate: [AuthGuard, DeverouillageGuard],
        loadChildren: () => import('./views/tresorerie/tresorerie.module').then((m) => m.TresorerieModule),
      },
      {
        
        path: 'reparation',
        canActivate: [AuthGuard, DeverouillageGuard],
        loadChildren: () => import('./views/module-reparation/reparation.module').then((m) => m.ReparationModule),
      },
      {
        
        path: 'atelier',
        canActivate: [AuthGuard, DeverouillageGuard],
        loadChildren: () => import('./views/module-atelier/atelier.module').then((m) => m.AtelierModule),
      },
    
      {
        
        path: 'magasin',
        canActivate: [AuthGuard, DeverouillageGuard],
        loadChildren: () => import('./views/module-magasin/magasin.module').then((m) => m.MagasinModule),
      },
      {
        
        path: 'document-administratif',
        canActivate: [AuthGuard, DeverouillageGuard],
        loadChildren: () =>
          import('./views/module-document-administratif/document-admin.module').then((m) => m.DocumentAdminModule),
      },
      {
        
        path: 'location-voiture',
        canActivate: [AuthGuard, DeverouillageGuard],
        loadChildren: () =>
          import('./views/location-voiture/location-voiture.module').then((m) => m.LocationVoitureModule),
      },
      {
        
        canActivate: [AuthGuard, DeverouillageGuard],
        path: 'administration',
        loadChildren: () => import('./views/administration/administration.module').then((m) => m.AdministrationModule),
      },
      
    ],
  },
  {
    path: '**',
    redirectTo: 'sessions/404',
  },
];
