import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import * as _ from "lodash";


import { NotificationService } from 'app/shared/services/notifications.service';
import { RestClientService } from 'app/shared/services/rest-client.service';
import Swal from "sweetalert2";
import { InfoUserService } from 'app/shared/services/info-user.service';
import { UrlDeconnecterService } from 'app/shared/services/url-deconnecter.service';

@Component({
  selector: 'app-change-mot-de-passe',
  templateUrl: './change-mot-de-passe.component.html',
  styleUrls: ['./change-mot-de-passe.component.scss']
})
export class ChangeMotDePasseComponent implements OnInit {

  items : Array<any> = [];
  itemToSearch: any = {};
  currentPage: number = 1;
   listItems: Array<any> = [];
  itemToSave: any = {};
  modalRef: BsModalRef;
 
 
  
  regexExp = /^[A-Z0-9_'%=+!`#~$*?^{}&|-]+([\.][A-Z0-9_'%=+!`#~$*?^{}&|-]+)*@[A-Z0-9-]+(\.[A-Z0-9-]+)+$/i;
  user: any = {};
  itemsPerPage: number = 5;
  totalItems: number;
  emailVar: any = {};
  busyGet: Subscription;
  busySave: Subscription;
  loading: boolean = false;
  endPoint: string = '/user/';
  currentUser: any = {}
   userCurrent = {};
   User:any = {};
   title ="Changer mot de passe"
  
  rolesItems : Array<any> = [];
 constructor(public bsModalRef: BsModalRef, private modalService: BsModalService ,private info : InfoUserService, private notification: NotificationService,    private restClient: RestClientService,private urlService: UrlDeconnecterService) { }

  
  
   confirmSaveItem(item) {
    let objToSave = Object.assign({}, item);
    const data2 = this.urlService.getItem('currentUser');
    if(this.userCurrent={}){  
      objToSave.login = data2["items"][0]["login"]
      objToSave.IdUser = data2["items"][0]["id"]

    }else {
      objToSave.login = this.userCurrent["login"]
      objToSave.IdUser = this.userCurrent["id"]
    }

    if(!item || !item.password){
      {
        this.notification.showNotification("snackbar-danger", " Veillez renseigner l'encien mot passe ",
        "bottom",
        "center")};
      return ;  
    }
    if(!item || !item.newPassword){
      {
        this.notification.showNotification("snackbar-danger", " Veillez renseigner le nouveau mot de passe ",
        "bottom",
        "center")};
      return ;  
    }
    if(!item || !item.ConfirmnewPassword){
      {
        this.notification.showNotification("snackbar-danger", " Veillez confirmer le nouveau mot de passe ",
        "bottom",
        "center")};
      return ;  
    }
    if(item.newPassword !== item.ConfirmnewPassword){
      {
        this.notification.showNotification("snackbar-danger", " Les mots de passes sont differents ",
        "bottom",
        "center")};
      return ;  
    }
   
     
   
      console.log(objToSave);
      
      Swal.fire({
         title: "Mot de passe",
        text: objToSave?.id ? "Vous êtes sur le point de modifier votre mot de passe. Voulez-vous poursuivre cette action ?" : "Vous êtes sur le point d'enregistrer votre mot de passe. Voulez-vous poursuivre cette action ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0665aa",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui",
        cancelButtonText: 'Non',
      }).then((result) => {
        if (result.value) {         
          this.saveItem(objToSave);
        }
      })
  };
   saveItem(item) {
    this.loading = true;
     let itemAEnregistrer = Object.assign({}, item); 

    var request = {
     "user":"1",
      "datas": [
        itemAEnregistrer
      ]
    }
    
    this.busySave = this.restClient.post(this.endPoint+''+ (itemAEnregistrer.id ? 'update' : 'create'), request)
    .subscribe(
      res => {
          console.log(itemAEnregistrer);
          console.log("resul", res);
          this.loading = false;

          if (!res['hasError']) {
            if (res['items'] && res['items'].length > 0) {

              this.notification.showNotification("snackbar-success",
                this.notification.formatMsgServeur(res['status']['message']),
                "bottom",
                "center");

              this.bsModalRef.hide();
            }
          } else {
            if (res['status'] && res['status']['message']) {
              this.notification.showNotification("snackbar-danger",
                this.notification.formatMsgServeur(res['status']['message']),
                "bottom",
                "center");
            }
          }
        },
        err => {
          this.notification.showNotification("snackbar-danger", this.notification.getMessageEndPointNotAvailble(),
            "bottom",
            "center");
          this.loading = false;
        }
      );
  }


  ngOnInit(): void {
    this.userCurrent = this.info.info["items"][0];
    const data2 = this.urlService.getItem('currentUser');
    if(this.userCurrent){
      this.itemToSave.login = this.info.info["items"][0]["login"]
    }else {
      this.itemToSave.login = data2["items"][0]["login"]
    }
  
    
  }
  
   
  
 
}
