import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from "lodash";
import { User } from '../models/user.model';
import { environment } from 'environments/environment';
// import { PasseWordConnectedService } from 'app/shared/services/passe-word-connected.service';


@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  public UserConnected = {};

  constructor(private http: HttpClient ) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public  setCurrentUser() {
    // Je viens de setter
     this.currentUserSubject.next(JSON.parse(localStorage.getItem('currentUser')));
  }

  login(username: string, password: string) {
    let request={
      data: {
        login: username,
        password: password,
      }
  }
  console.log(request);
  
    return this.http
      .post<any>(`${environment.urlBase}user/login`, request)
      .pipe(
        map((user:any) => {
          
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          if(user && user.items && user.items.length>0){
            // Faire remonter serviceId
              localStorage.setItem('currentUser', JSON.stringify(user.items[0]));
              this.UserConnected = user;
              localStorage.setItem('currentUser', JSON.stringify(user));
              this.currentUserSubject.next(user);
          }
          return user;
        })
      );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    return of({ success: false });
  }
}
