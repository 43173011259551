import { Component, OnInit, OnDestroy, AfterViewInit , Input } from "@angular/core";
import { NavigationService } from "../../../shared/services/navigation.service";
import { ThemeService } from "../../services/theme.service";
import { Subscription } from "rxjs";
import { ILayoutConf, LayoutService } from "app/shared/services/layout.service";
import { JwtAuthService } from "app/shared/services/auth/jwt-auth.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "app/shared/services/auth.service";
import { InfoUserService } from "app/shared/services/info-user.service";
import { UnsubscribeOnDestroyAdapter } from "app/shared/UnsubscribeOnDestroyAdapter";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-sidebar-side",
  templateUrl: "./sidebar-side.component.html",
  styleUrls: ['./sidebar-side.component.scss'],

})
export class SidebarSideComponent extends UnsubscribeOnDestroyAdapter implements OnInit, OnDestroy, AfterViewInit {
  public menuItems: any[];
  public hasIconTypeMenuItem: boolean;
  public iconTypeMenuTitle: string;
  private menuItemsSub: Subscription;
  public layoutConf: ILayoutConf;  public userCurrent = {};
  public photo = ""
  public menu  :any = [];
  public menu1 :any  = [];
  public menuOff: any = [];
  public menuOffOff: any = [];
  public menuOffOffFinal: any = [];
  public datasChil1 : any = [];
  public datasChil2 : any = [];
  public datasSub1 : any = [];
  public datasSub2 : any = [];
  currentTime: Date;
  today = new Date();

  constructor(
    private navService: NavigationService,
    public themeService: ThemeService,
    public jwtAuth: JwtAuthService,
    private layout: LayoutService, 
    public translate: TranslateService,
    private router: Router, private authService: AuthService,
    private info: InfoUserService,
    private auth : AuthService,
    private datePipe: DatePipe,

  ) {
    super()
  }

  ngOnInit() {
    this.menu = this.auth.currentUserValue["items"][0]["fonctionnalites"];
    this.iconTypeMenuTitle = this.navService.iconTypeMenuTitle;  
    setTimeout(() => {
      this.getMenu()
    }, 1) 
    this.currentTime = new Date();
    setInterval(() => {
     this.getCurrentDayInFrench()
     this.currentTime = new Date();  
   }, 1000); 

    this.iconTypeMenuTitle = this.navService.iconTypeMenuTitle;
    this.menuItemsSub = this.navService.menuItems$.subscribe(menuItem => {
      this.menuItems = menuItem;
      //Checks item list has any icon type.
      this.hasIconTypeMenuItem = !!this.menuItems.filter(
        item => item.type === "icon"
      ).length;
    }); 
    this.layoutConf = this.layout.layoutConf;
  }
  getMenu(){
    this.menuItemsSub = this.navService.menuItems$.subscribe(menuItem => {
      this.menu1 = this.menuItems
      this.menu1.forEach((e:any) => {
        this.menu.forEach((a:any)=> {
          if(e.name == a.libelle  && !a.datasChildren) {
            this.menuOff.push((e))
          }
          if(e.name == a.libelle && a.datasChildren && a.datasChildren.length){            
            this.datasSub1.push((e))
            this.datasChil1.push(a)
          }
        })
      })  
      this.datasSub1.forEach((a) => {
        this.datasChil1.forEach((e) => {
          if( a.name == e.libelle  ){
            let arr = []
            a.sub.forEach((x) => {
              e.datasChildren.forEach((y) => {
                if(x.name == y.libelle && !y.datasChildren){
                  arr.push(x)
                  if(arr.length){
                    a.sub = Array.from(new Set( [...arr]))
                   }
                   this.menuOff.push(a)
                }
                
                if(x.name == y.libelle && y.datasChildren && y.datasChildren.length){
                  this.datasChil2.push(e)
                  this.datasSub2.push(a)
                }
              })
            })
           
            
          }
        })
      })
      this.menuOff = Array.from(new Set(this.menuOff))
      let position = 3;
      this.datasSub2.forEach((a)=> {
        this.datasChil2.forEach((b) => {
          if(a.name == b.libelle){
          a.sub.forEach((o)=> {
            b.datasChildren.forEach((p) => {
              let arr = [];
              if(o.name == p.libelle){
                o.sub.forEach((q) => {
                  p.datasChildren.forEach((r) => {
                    if(q.name == r.libelle){
                      arr.push(q)
                    }
                    if(arr.length){
                      o.sub = [...arr]
                     }
                     this.menuOff.splice(position, 0, a)
                  }) 
                })
              }
            })
          })
          
          }
        })        
      })
      this.menuOff = Array.from(new Set(this.menuOff))
      this.menuOff.forEach((e) => {        
        if( e.name.slice(0,4).toLocaleUpperCase().includes(this.router.url.slice(1,5).toLocaleUpperCase())){
          e.open = true         
        }else {
          e.open = false
        }
      })
    });
    this.layoutConf = this.layout.layoutConf;
  }
  ngAfterViewInit() {}
  ngOnDestroy() {
    if (this.menuItemsSub) {
      this.menuItemsSub.unsubscribe();
    }
  }
  toggleCollapse() {
    if (
      this.layoutConf.sidebarCompactToggle
    ) {
        console.log(this.layoutConf.sidebarCompactToggle);
        
        this.layout.publishLayoutChange({
        sidebarCompactToggle: false
      });
    } else {
        this.layout.publishLayoutChange({
            sidebarCompactToggle: true
          });
          console.log(this.layoutConf.sidebarCompactToggle);

    }
  }
  logout() {
    this.subs.sink = this.authService.logout().subscribe((res) => {
      if (!res.success) {
        this.router.navigate(["sessions/login"]);
      }
    });
  

  } 
  getCurrentDayInFrench(): string {
    const today = new Date();
    let formattedDay = this.datePipe.transform(today, 'EEEE', 'fr');
    if(formattedDay == 'Monday'){
      formattedDay = "Lundi"
    }else if(formattedDay == 'Tuesday'){
      formattedDay = "Mardi"
    } else if(formattedDay == 'Wednesday'){
      formattedDay = "Mercredi"
    }else if(formattedDay == 'Thursday'){
      formattedDay = "Jeudi"
    }else if(formattedDay == 'Friday'){
      formattedDay = "Vendredi"
    }else if(formattedDay == 'Saturday'){
      formattedDay = "Samedi"
    }else if(formattedDay == 'Sunday'){
      formattedDay = "Dimanche"
    }
    return formattedDay;
  }
}
